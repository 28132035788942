import React from "react";
import { useEffect } from "react";
import { Alert, Button, Col, Form, Image, Row } from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import * as _api from "../../Apis/Me_apis";
import { useKeycloak } from "@react-keycloak/web";
import { userContext } from "../../Stores/Store";
import { useContext } from "react";
import { useState } from "react";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const user = useContext(userContext);
  const [profile, setProfile] = useState();
  const [password, setPassword] = useState();
  const [show, setShow] = useState(false);
  const [competitions, setCompetitions] = useState();
  const [competitionId, setCompetitionId] = useState(null);
  const [skills, setSkills] = useState();
  const [skillId, setSkillId] = useState(null);
  const [alarm, setAlarm] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [schedule, setSchedule] = useState(5);
  const [urlIcs, setUrlIcs] = useState();
  const [todo, setTodo] = useState();

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    setLoading(true);
    _api
      .getProfile(keycloak.token)
      .then((res) => setProfile(res))
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      _api
        .getSkillsAsync(keycloak.token, competitionId)
        .then((res) => setSkills(res))
        .then(() => setLoading(false));
    } else {
      setSkills();
      setSkillId(null);
    }
  }, [competitionId]);

  useEffect(() => {
    setLoading(true);
    _api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  const updateProfil = () => {
    if (profile.first_name && profile.last_name && profile.email) {
      if (!profile.digest_freq) profile.digest_freq = null;
      _api
        .postProfile(keycloak.token, profile)
        .then((res) => {
          setProfile(res);
        })
        .then(() => setShow(true));
    }
  };

  const changePassword = () => {
    if (password) {
      _api
        .postPassowrd(keycloak.token, password)
        .then((res) => {
          setProfile(res);
        })
        .then(() => setShow(true));
    }
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        return (
          <option
            selected={competition.id == competitionId}
            value={competition.id}
          >
            {competition.id} - {competition.name}
          </option>
        );
      });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          return (
            <option value={skill.number}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  const generateLink = () => {
    let url =
      process.env.REACT_APP_API_URL + `/me/ics/${profile.calendar_token}`;
    if (competitionId || alarm || assigned || todo) url = url + "?";
    if (competitionId) url = url + `competition=${competitionId}&`;
    if (competitionId && skillId) url = url + `skill=${skillId}&`;
    if (alarm) url = url + `with_alarm=true&`;
    if (alarm && schedule) url = url + `alarm_schedule=${schedule}&`;
    if (assigned) url = url + `only_assigned=${assigned}&`;
    if (todo) url = url + `with_todo=${todo}&`;

    setUrlIcs(url);
  };

  const listHours = () => {
    let arrayHours = [];
    for (let index = 0; index < 24; index++) {
      if (index == 0) {
        arrayHours.push("Minuit");
      } else {
        arrayHours.push(index);
      }
    }
    return (
      arrayHours &&
      arrayHours.map((hour, i) => {
        return <option value={i}>{hour} {i != 0 ? "Heure(s)" : ""}</option>;
      })
    );
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header">
        <Col>
          <Image
            className="img_title"
            src="images/icons/bigmenu_documents-formation.svg"
          />
          <span className="title">Profil</span>
        </Col>
      </Row>
      <Row>
        <Alert
          show={show}
          variant="success"
          onClose={() => setShow(false)}
          dismissible
        >
          Modifications effectuées !
        </Alert>
      </Row>
      <Row>
        <Col sm={6}>
          <h4>Vos informations</h4>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={profile && profile.last_name}
                    onChange={(e) =>
                      setProfile({ ...profile, last_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={profile && profile.first_name}
                    onChange={(e) =>
                      setProfile({ ...profile, first_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={profile && profile.email}
                    onChange={(e) =>
                      setProfile({ ...profile, email: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="phone"
                    name="phone_number"
                    value={profile && profile.phone_number}
                    onChange={(e) =>
                      setProfile({ ...profile, phone_number: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <h4>Paramètres des notifications</h4>
            <Row>
              <Col>
                <Form.Select
                  name="digest_freq"
                  onChange={(e) =>
                    setProfile({ ...profile, digest_freq: e.target.value })
                  }
                  size="sm"
                  value={profile && profile.digest_freq}
                >
                  <option value="">Désactiver les notifications</option>
                  <option value="weekly">Toutes les semaines</option>
                  <option value="daily">Tous les jours</option>
                  <option value="hourly">Toutes les heures</option>
                </Form.Select>
              </Col>
              {profile && profile.digest_freq == "weekly" && (
                <Col>
                  <Form.Select
                    name="digest_day"
                    onChange={(e) =>
                      setProfile({ ...profile, digest_day: e.target.value })
                    }
                    size="sm"
                    value={profile && profile.digest_day}
                  >
                    <option value="1">Lundi</option>
                    <option value="2">Mardi</option>
                    <option value="3">Mercredi</option>
                    <option value="4">Jeudi</option>
                    <option value="5">Vendredi</option>
                    <option value="6">Samedi</option>
                    <option value="7">Dimanche</option>
                  </Form.Select>
                </Col>
              )}
              {profile &&
                (profile.digest_freq == "daily" ||
                  profile.digest_freq == "weekly") && (
                  <Col>
                    <Form.Select
                      name="digest_hour"
                      onChange={(e) =>
                        setProfile({ ...profile, digest_hour: e.target.value })
                      }
                      size="sm"
                      value={profile && profile.digest_hour}
                    >
                      {listHours()}
                    </Form.Select>
                  </Col>
                )}
            </Row>
            <Row>
              <Col>
                <br />
                <Button onClick={() => updateProfil()}>
                  Modifier le profil
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col sm={6}>
          <h4>Changer votre mot de passe</h4>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => changePassword()}>
                  Changer le mot de passe
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <h4>Exporter le calendrier .ics</h4>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Select
                  name="competition_select"
                  onChange={(e) => setCompetitionId(e.target.value)}
                  size="sm"
                  value={competitionId}
                >
                  <option value="">0 - Toutes les compétitions</option>
                  {competitions && listCompetitions()}
                </Form.Select>
              </Col>
              <Col>
                <Form.Select
                  size="sm"
                  name="skill_select"
                  onChange={(e) => setSkillId(e.target.value)}
                  value={skillId ? skillId : ""}
                >
                  <option value="">0 - Tous les métiers</option>
                  {skills && listSkills()}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    label="Activer l'alarme"
                    name="alarm"
                    type="switch"
                    value="true"
                    onChange={(e) => {
                      if (e.target.checked) setAlarm(true);
                      if (!e.target.checked) setAlarm(false);
                    }}
                    checked={alarm}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="alarm_schedule"
                    onChange={(e) => setSchedule(e.target.value)}
                    size="sm"
                    value={schedule}
                    disabled={!alarm && "true"}
                  >
                    <option value="0">à l'heure prévue</option>
                    <option value="5">5mn avant</option>
                    <option value="15">15mn avant</option>
                    <option value="30">30mn avant</option>
                    <option value="60">1h avant</option>
                    <option value="120">2h avant</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    label="Evènements assignés seulement"
                    name="assigned"
                    type="switch"
                    value="true"
                    onChange={(e) => {
                      if (e.target.checked) setAssigned(true);
                      if (!e.target.checked) setAssigned(false);
                    }}
                    checked={assigned}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    label="Intégrer les tâches"
                    name="todo"
                    type="switch"
                    value="true"
                    onChange={(e) => {
                      if (e.target.checked) setTodo(true);
                      if (!e.target.checked) setTodo(false);
                    }}
                    checked={todo}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => generateLink()}>Générer</Button>&nbsp;
              </Col>
            </Row>
            {urlIcs && (
              <>
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Alert variant="success">Lien généré avec succès.</Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>{urlIcs}</Col>
                  <Col xs lg="2">
                    <Button
                      variant="info"
                      onClick={() => {
                        navigator.clipboard.writeText(urlIcs);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-clipboard"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                      </svg>
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
