import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./navigation.scss";
import * as _api from "../../Apis/Bookmarks_apis";
import { navigationleft_data } from "../../Helpers/Data";
import { userContext } from "../../Stores/Store";
import { useContext } from "react";

const Navigation = () => {
  const user = useContext(userContext);

  const listNavigationleft = () =>
    navigationleft_data.map((navigationleft) => {
      if (navigationleft.id == 9) {
        return (
          <>
            {user.userRoles &&
            (user.userRoles.find((obj) => obj == "admin") ||
              user.userRoles.find((obj) => (obj == "staff"))) ? (
              <NavLink exact to={navigationleft.route}>
                <img src={navigationleft.icon} />
              </NavLink>
            ) : (
              ""
            )}
          </>
        );
      } else {
        return (
          <NavLink exact to={navigationleft.route}>
            <img src={navigationleft.icon} />
          </NavLink>
        );
      }
    });

  return (
    <>
      <div className="navigation_left">
        <span id="triangle-bottomleft"></span>
        <Nav defaultActiveKey="/" className="flex-column vertical_menu">
          {listNavigationleft()}

          {/* Ajout manuel du lien dropbox tutoriel et suppr dans le data helper*/}
          <a
                href="https://www.dropbox.com/sh/wadgyy5eb04l38h/AACorG7pUDIFJ0WM2HdpLOKEa?dl=0"
                target="_blank"
              >
                <img src={window.location.origin+"/images/icons/smallmenu_aides.svg"} />
              </a>
        </Nav>
      </div>
    </>
  );
};

export default Navigation;
