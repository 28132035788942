import "../App.css";
import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../Keycloak";
import Header from "./Header";
import Displayer from "./Displayer";
import Navigation from "../Components/Navigation/Navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Components/Home/Home";
import Contacts from "../Components/Contacts/Contacts";
import UserContextProvider from "../Stores/Store";
import NotFound from "../Components/NotFound";
import Documents from "../Components/Documents/Documents";
import PrivateRoute from "../Helpers/PrivateRoute";
import Planning from "../Components/Planning/Planning";
import Evaluations from "../Components/Evaluations/Evaluations";
import Entrainements from "../Components/Entrainements/Entrainements";
import Cis from "../Components/Cis/Cis";
import Questionnaires from "../Components/Questionnaires/Questionnaires";
import Aides from "../Components/Aides/Aides";
import Configurations from "../Components/Configuration/Configurations";
import DocumentView from "../Components/Documents/DocumentView";
import Profile from "../Components/Profile/Profile";
import Cgu from "../Components/Cgu/Cgu";
import Tutoriels from "../Components/Tutoriels/Tutoriels";
import Entrainement from "../Components/Entrainements/Entrainement";

function App() {
  return (
    <>
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <PrivateRoute>
            <UserContextProvider>
              <Header />
              <Navigation />
              <Displayer>
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  <Route path="/" element={<Home />} />
                  <Route path="contacts" element={<Contacts />} />
                  <Route path="planning" element={<Planning />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="document/:documentId" element={<DocumentView />} />
                  <Route path="evaluations" element={<Evaluations />} />
                  <Route path="entrainements" element={<Entrainements />} />
                  <Route path="entrainement/:entrainementId" element={<Entrainement />} />
                  <Route path="criteres" element={<Cis />} />
                  <Route path="questionnaires" element={<Questionnaires />} />
                  <Route path="aides" element={<Aides />} />
                  <Route path="configuration" element={<Configurations />} />
                  <Route path="profil" element={<Profile />} />
                  <Route path="cgu" element={<Cgu />} />
                  <Route path="tutoriels" element={<Tutoriels />} />
                </Routes>
              </Displayer>
            </UserContextProvider>
          </PrivateRoute>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </>
  );
}

export default App;
