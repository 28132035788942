import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../Stores/Store";
import Line from "./CisCharts";
import * as _api from "../../Apis/Criteres_apis";
import Loading from "../../Helpers/Loading";
import { Col, Form, Image, Row } from "react-bootstrap";

const CisWidgetCharts = () => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [histories, setHistories] = useState();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();

  useEffect(() => {
    if ((username, user.competitionid, user.skillid)) {
      setLoading(true);
      _api
        .getHistories(
          keycloak.token,
          username,
          user.competitionid,
          user.skillid
        )
        .then((res) => {
          setHistories(res);
        })
        .then(() => setLoading(false));
    }
  }, [username]);
  
  useEffect(() => {
    setUsername("");
  }, [user.skillid, user.competitionid]);

  const listCompetitors = () =>
    user.contactsSkill
      .filter((item) => item.role == "competitor")
      .map((competitor) => {
        return (
          <option value={competitor.username} selected={competitor.username == username && "true"} >
            {competitor.first_name + " " + competitor.last_name}
          </option>
        );
      });

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header" style={{marginTop:20}}>
        <Col sm={4}>
          <Image
            className="img_title"
            src="images/icons/bigmenu_criteres-evaluation.svg"
          />
          <span className="title">Progression</span>
        </Col>
      </Row>
      <Row>
        {" "}
        <Form.Select
          style={{ border: "0px", color: "#1E4EE2" }}
          name="bareme"
          size="sm"
          onChange={(e) => setUsername(e.target.value)}
        >
          {" "}
          {!username && <option>Choisir un competiteur</option>}
          {user.contactsSkill && listCompetitors()}
        </Form.Select>
      </Row>
      <Row style={{ height: 500, backgroundColor: "#FFF", border: "1px solid #EEE" }}>
        {histories && <Line data={histories} />}
      </Row>
    </>
  );
};

export default CisWidgetCharts;
