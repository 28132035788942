import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AlertMessage from "../../Helpers/AlertMessage";
import { userContext } from "../../Stores/Store";
import * as events_api from "../../Apis/Events_apis";
import { controlForm } from "../../Helpers/FormValidate";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import Commentaires from "../Commentaires/Commentaires";
import Loading from "../../Helpers/Loading";

export const PlanningView = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [event, setEvent] = useState({});
  const [errorForm, setErrorForm] = useState();
  const [showTime, setShowTime] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => props.show(false);

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  const deleteEvent = () => {
    let checkErrors = controlForm("planning", event); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      events_api.deleteEvent(keycloak.token, event.id).then((results) => {
        if (results) {
          props.maj(event, "delete");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const updateEvent = () => {
    // On force l'heure si All_Day
    if (!showTime) {
      let startDate = moment(event.start).format("YYYY-MM-DDT08:00:00");
      let endDate = moment(event.end).format("YYYY-MM-DDT23:00:00");
      event.start = new Date(startDate);
      event.end = new Date(endDate);
    }
    let checkErrors = controlForm("planning", event); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      events_api.postEvent(keycloak.token, event.id, event).then((results) => {
        if (results) {
          props.maj(event, "update");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const addEvent = () => {
    // On force l'heure si All_Day
    if (!showTime) {
      let startDate = moment(event.start).format("YYYY-MM-DDT08:00:00");
      let endDate = moment(event.end).format("YYYY-MM-DDT23:00:00");
      event.start = new Date(startDate);
      event.end = new Date(endDate);
    }
    let checkErrors = controlForm("planning", event); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      events_api
        .putEvent(keycloak.token, user.competitionid, user.skillid, event)
        .then((results) => {
          if (results) {
            props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
    } else {
      setErrorForm(checkErrors);
    }
  };

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      events_api
        .getEventIdAsync(keycloak.token, props.id)
        .then((res) => {
          setEvent(res);
          res.all_day && setShowTime(false);
        })
        .then(() => setLoading(false));
    } else {
      setEvent({
        color: "blue",
        title: "",
        start: new Date(),
        end: new Date(),
        localisation: "",
        note: "",
        all_day: false,
        users: [],
      });
    }
  }, [props.id]);

  const handleChangeDate = (date, field) => {
    if (field === "start" && date > event.end) event.end = date;
    setEvent({ ...event, [field]: date });
  };

  const handleChangeAllDay = (e) => {
    if (e.target.checked) {
      setShowTime(false);
      setEvent({ ...event, [e.target.name]: e.target.checked });
    }
    if (!e.target.checked) {
      setShowTime(true);
      setEvent({ ...event, [e.target.name]: e.target.checked });
    }
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "users":
        if (e.target.checked) {
          setEvent({ ...event, users: [...event.users, e.target.value] });
        } else {
          let temptable = [...event.users];
          let index = temptable.indexOf(e.target.value);
          if (index !== -1) {
            temptable.splice(index, 1);
            setEvent({ ...event, users: temptable });
          }
        }
        break;
      default:
        setEvent({ ...event, [e.target.name]: e.target.value });
        break;
    }
  };

  const listContacts = () =>
    user.contactsSkill.map((contact) => {
      return (
        <Form.Check
          disabled={props.id && !event.editable && true}
          inline
          label={contact.last_name + " " + contact.first_name}
          name="users"
          type="checkbox"
          value={contact.username}
          id={`inline-radio-${contact.username}`}
          onChange={handleChange}
          checked={
            event.users && event.users.indexOf(contact.username) !== -1
              ? true
              : false
          }
        />
      );
    });

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id ? "Modifier" : "Ajouter"} un événement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Label>Informations</Form.Label>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  disabled={props.id && !event.editable && true}
                  className="event_blue"
                  inline
                  name="color"
                  type="radio"
                  value="blue"
                  onChange={handleChange}
                  checked={event.color === "blue" ? true : false}
                />
                <Form.Check
                  disabled={props.id && !event.editable && true}
                  className="event_green"
                  inline
                  name="color"
                  type="radio"
                  value="green"
                  onChange={handleChange}
                  checked={event.color === "green" ? true : false}
                />
                <Form.Check
                  disabled={props.id && !event.editable && true}
                  className="event_orange"
                  inline
                  name="color"
                  type="radio"
                  value="orange"
                  onChange={handleChange}
                  checked={event.color === "orange" ? true : false}
                />
                <Form.Check
                  disabled={props.id && !event.editable && true}
                  className="event_red"
                  inline
                  name="color"
                  type="radio"
                  value="red"
                  onChange={handleChange}
                  checked={event.color === "red" ? true : false}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Control
                readOnly={props.id && !event.editable && true}
                placeholder="Nom de l'événement"
                name="title"
                value={event.title}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col sm={2}>
              <Form.Label htmlFor="start">Date de début</Form.Label>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <DateTimePicker
                  disabled={props.id && !event.editable && true}
                  locale="fr-FR"
                  value={
                    typeof event.start === "string"
                      ? new Date(event.start)
                      : event.start
                  }
                  name="start"
                  onChange={(value) => handleChangeDate(value, "start")}
                  format={showTime ? "" : "d/MM/yyyy"}
                  required="true"
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Label htmlFor="end">Date de fin</Form.Label>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <DateTimePicker
                  disabled={props.id && !event.editable && true}
                  locale="fr-FR"
                  name="end"
                  value={
                    typeof event.end === "string"
                      ? new Date(event.end)
                      : event.end
                  }
                  onChange={(value) => handleChangeDate(value, "end")}
                  format={showTime ? "" : "d/MM/yyyy"}
                  required="true"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  disabled={props.id && !event.editable && true}
                  label="Toute la journée"
                  name="all_day"
                  type="switch"
                  value="true"
                  onChange={handleChangeAllDay}
                  checked={event.all_day}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Control
                readOnly={props.id && !event.editable && true}
                placeholder="Lieu de l'événement"
                name="localisation"
                value={event.localisation}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Control
                readOnly={props.id && !event.editable && true}
                as="textarea"
                placeholder="Note"
                name="note"
                value={event.note}
                onChange={handleChange}
                rows={3}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              {user.contactsSkill && listContacts()}
            </Form.Group>
          </Row>

          {/* Affichage des erreurs */}
          {errorForm && (
            <AlertMessage messages={errorForm} errorMaj={initError} />
          )}
        </Form>

        {props.id && <Commentaires module="events" id={event.id} />}
      </Modal.Body>

      <Modal.Footer>
        {user.userRights && event.editable && (
          <Button onClick={() => updateEvent()}>Modifier</Button>
        )}
        {!props.id && <Button onClick={() => addEvent()}>Ajouter</Button>}

        {props.id && event.editable && (
          <Button variant="danger" onClick={() => deleteEvent()}>
            Supprimer
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanningView;
