export const roles = [
  { name: "Competiteur", name_db: "competitor", notadminlist : true },
  { name: "Equipe métier", name_db: "team", notadminlist : true  },
  { name: "Expert", name_db: "expert", notadminlist : true  },
  { name: "M.O.S", name_db: "tl", notadminlist : false  },
  { name: "Team Leader", name_db: "st", notadminlist : false  },
  { name: "Permanent WSFR", name_db: "staff", notadminlist : false  },
  { name: "Administrateur", name_db: "admin", notadminlist : false  },
];

export const navigationboard_data = [
  {
    id: 1,
    title: "Progression",
    route: "criteres",
    image: window.location.origin+"/images/navboard/criteres.svg",
    tooltip: "Progression",
  },

  {
    id: 2,
    title: "Sujets d'entrainements",
    route: "entrainements",
    image: window.location.origin+"/images/navboard/entrainements.svg",
    tooltip: "Sujets d'entrainements",
  },

  {
    id: 3,
    title: "Documents",
    route: "documents",
    image: window.location.origin+"/images/navboard/docs.svg",
    tooltip: "Documents",
  },

  // {
  //   id: 3,
  //   title: "Evaluation",
  //   route: "evaluations",
  //   image: "./images/navboard/evaluation.svg",
  //   tooltip: "Evaluation",
  // },

  {
    id: 4,
    title: "Planning",
    route: "planning",
    image: window.location.origin+"/images/navboard/planning.svg",
    tooltip: "Planning",
  },

  {
    id: 6,
    title: "Contacts",
    route: "contacts",
    image: window.location.origin+"/images/navboard/contacts.svg",
    tooltip: "Contacts",
  },

  {
    id: 7,
    title: "Questionnaires",
    route: "questionnaires",
    image: window.location.origin+"/images/navboard/questionnaire.svg",
    tooltip: "Questionnaires",
  },
];

export const bookmarks_data = [
  {
    id: 1,
    title: "CIS",
    route: "https://cis.worldskills-france.org/",
    image: window.location.origin+"/images/navboard/cis.svg",
    tooltip: "CIS",
  },

  {
    id: 2,
    title: "Member Area",
    route: "https://forums.worldskills.org/",
    image: window.location.origin+"/images/navboard/forum.svg",
    tooltip: "Member Area",
  },

  {
    id: 3,
    title: "TravelPerk",
    route: "https://app.travelperk.com/login",
    image: window.location.origin+"/images/navboard/egencia.svg",
    tooltip: "Egencia",
  },

  {
    id: 4,
    title: "Note de Frais",
    route: "https://www.notys.com/wsfr/",
    image: window.location.origin+"/images/navboard/notedefrais.svg",
    tooltip: "Note de Frais",
  },

  {
    id: 6,
    title: "Trombinoscope",
    route: "assets/Presentation_equipe_de_France.pdf",
    image: window.location.origin+"/images/navboard/trombinoscope.svg",
    tooltip: "Trombinoscope",
  },

  {
    id: 7,
    title: "Site Web",
    route: "https://www.worldskills-france.org/",
    image: window.location.origin+"/images/navboard/siteweb.svg",
    tooltip: "Site Web",
  },
];

export const navigationleft_data = [
  {
    id: 1,
    title: "Home",
    route: "/",
    icon: window.location.origin+"/images/icons/smallmenu_home.svg",
    custom: false,
  },

  {
    id: 2,
    title: "Planning",
    route: "planning",
    icon: window.location.origin+"/images/icons/smallmenu_planning.svg",
    custom: false,
  },

  {
    id: 3,
    title: "Contacts",
    route: "contacts",
    icon: window.location.origin+"/images/icons/smallmenu_contacts.svg",
    custom: false,
  },

  {
    id: 4,
    title: "Member Area",
    route: "",
    icon: window.location.origin+"/images/icons/smallmenu_forum.svg",
    custom: false,
  },

  {
    id: 5,
    title: "Entrainements",
    route: "entrainements",
    icon: window.location.origin+"/images/icons/smallmenu_sujets-entrainements.svg",
    custom: false,
  },

  {
    id: 6,
    title: "Documents",
    route: "documents",
    icon: window.location.origin+"/images/icons/smallmenu_documents-formation.svg",
    custom: false,
  },

  // {
  //   id: 7,
  //   title: "Evaluations",
  //   route: "evaluations",
  //   icon: "./images/icons/smallmenu_evaluation.svg",
  //   custom: false,
  // },
  // {
  //   id: 8,
  //   title: "Aides",
  //   route: "https://www.dropbox.com/sh/wadgyy5eb04l38h/AACorG7pUDIFJ0WM2HdpLOKEa?dl=0",
  //   icon: "./images/icons/smallmenu_aides.svg",
  //   custom: false,
  // },

  {
    id: 9,
    title: "Configuration",
    route: "configuration",
    icon: window.location.origin+"/images/icons/smallmenu_configurations.svg",
    custom: false,
  },
];
