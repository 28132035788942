import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as me_api from "../../Apis/Me_apis";
import * as contact_api from "../../Apis/Contacts_apis";
import { BsArrowRepeat } from "react-icons/bs";
import Loading from "../../Helpers/Loading";
import moment from "moment";

const ListCompsAndSkills = (props) => {
  const user = useContext(userContext);
  const setDiffDays = props.setDiffDays;

  const { keycloak, initialized } = useKeycloak();

  const [skills, setSkills] = useState();
  const [competitions, setCompetitions] = useState();
  const [loading, setLoading] = useState(false);

  // Initialisation des competitions
  useEffect(() => {
    setLoading(true);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        if (localStorage.getItem("competitionid")) {
          // Pour le refresh on stock l'id de competition dans un sessionStorage
          user.setCompetitionid(localStorage.getItem("competitionid"));
        } else {
          user.setCompetitionid(res[res.length - 1].id);
        }
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  // Initialisation des métiers
  useEffect(() => {
    if (user.competitionid !== 0) {
      countDateStart();
      setLoading(true);
      me_api
        .getSkillsAsync(keycloak.token, user.competitionid)
        .then((res) => {
          if (localStorage.getItem("skillid")) {
            // Pour le refresh on stock l'id du skill dans un sessionStorage
            user.setSkillid(localStorage.getItem("skillid"));
          } else {
            user.setSkillid(res[res.length - 1].number);
          }
          setSkills(res);
        })
        .then(() => setLoading(false));
    }
  }, [user.competitionid]);

  const countDateStart = () => {
    if (user.competitionid && competitions) {
      let date1 = competitions.find(
        (element) => element.id == user.competitionid
      ).date_start;
      let date2 = moment(new Date()).format("YYYY-MM-DD");
      let difference = moment(date1).diff(date2, "days");
      console.log(difference);
      if (difference > 0) {
        setDiffDays("⏱ J-" + difference);
      } else if (difference == 0) {
        setDiffDays("Jour J ! C'est parti 🏅💪");
      } else if (difference < 0 && difference > -5) {
        setDiffDays("Allez la Team, on est tous avec vous ! 🏅💪");
      } else {
        setDiffDays();
      }
    }
  };

  useEffect(() => {
    countDateStart();
  }, [competitions]);

  // Initialisation des droits et liste des contacts
  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      me_api
        .getRightsAsync(keycloak.token, user.competitionid, user.skillid)
        .then(
          (res) => user.setUserRights(res) // Probleme de context avec l'objet, solution : Serialisation
        );
      me_api
        .getRolesAsync(keycloak.token, user.competitionid, user.skillid)
        .then(
          (res) => user.setUserRoles(res) // Probleme de context avec l'objet, solution : Serialisation
        );
      contact_api
        .getContactsAsync(keycloak.token, user.competitionid, user.skillid)
        .then((res) => {
          user.setContactsSkill(res);
        })
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "skill_select":
        user.setSkillid(event.target.value);
        localStorage.setItem("skillid", event.target.value);
        break;
      case "competition_select":
        user.setCompetitionid(event.target.value);
        localStorage.setItem("competitionid", event.target.value);
        break;
      default:
        break;
    }
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        if (!localStorage.getItem("competitionid")) {
          localStorage.setItem("competitionid", competition.id);
        }
        return (
          <option
            selected={competition.id == localStorage.getItem("competitionid")}
            value={competition.id}
          >
            {competition.name}
          </option>
        );
      });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          if (!localStorage.getItem("skillid")) {
            localStorage.setItem("skillid", skill.number);
          }
          return (
            <option value={skill.number}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  const reloadLists = () => {
    setLoading(true);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        if (localStorage.getItem("competitionid")) {
          // Pour le refresh on stock l'id de competition dans un sessionStorage
          user.setCompetitionid(localStorage.getItem("competitionid"));
        } else {
          user.setCompetitionid(res[res.length - 1].id);
        }
        user.setCompetitionid(res[res.length - 1].id);
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Col sm={3}>
        <Form.Select
          name="competition_select"
          size="sm"
          value={user.competitionid}
          onChange={handleChange}
        >
          {competitions && listCompetitions()}
        </Form.Select>
      </Col>
      <Col sm={3}>
        <Form.Select
          size="sm"
          name="skill_select"
          value={user.skillid}
          onChange={handleChange}
        >
          {skills && listSkills()}
        </Form.Select>
      </Col>
      <Col sm={3}>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => reloadLists()}
        >
          <BsArrowRepeat />
        </Button>
      </Col>
    </>
  );
};

export default ListCompsAndSkills;
