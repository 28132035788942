import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const Tutoriels = () => {
    return (
        <>
        <Row className="header">
          <Col sm={12}>
            <Image
              className="img_title"
              src="images/icons/bigmenu_documents-formation.svg"
            />
            <span className="title">Tutoriels</span>
          </Col>
        </Row>
        <iframe src="https://www.dropbox.com/sh/wadgyy5eb04l38h/AACorG7pUDIFJ0WM2HdpLOKEa?dl=0"></iframe>
        <Row>
        </Row>
      </>
    );
};

export default Tutoriels;