import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import { useKeycloak } from "@react-keycloak/web";
import AlertMessage from "../../Helpers/AlertMessage";

const Skills = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [skill, setSkill] = useState("");
  const [skills, setSkills] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [confirmDelete, setConfirmDelete] = useState();
  const [skillAdd, setSkillAdd] = useState({ id: "", name: "" });
  const [errorForm, setErrorForm] = useState();

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    setLoading(true);
    _api
      .getSkills(keycloak.token)
      .then((res) => setSkills(res))
      .then(() => setLoading(false));
  }, []);

  const addSkill = () => {
    if (skillAdd.name && skillAdd.number) {
      _api.putSkill(keycloak.token, skillAdd).then((res) => {
        if (res.status != "201" || res.status != "200") {
          setErrorForm("Code Erreur " + res);
        } else {
          setSkills([res, ...skills]);
          eraseForm();
        }
      });
    }
  };

  const updateSkill = () => {
    if (skill.name) {
      _api.postSkill(keycloak.token, skill.number, skill.name).then((res) => {
        if (res.status != "201" || res.status != "200") {
          setErrorForm("Code Erreur " + res);
        } else {
          let arrayTemp = [];
          let skillIndex = skills.findIndex((obj) => obj.number == res.number);
          arrayTemp = [...skills];
          arrayTemp[skillIndex] = res;
          setSkills(arrayTemp);
          eraseForm();
        }
      });
    }
  };

  const deleteSkill = () => {
    if (skill.number) {
      if (confirmDelete == skill.name) {
        _api
          .deleteSkill(keycloak.token, skill.number)
          .then((res) => {
            setSkills(skills.filter((item) => item.number !== skill.number));
          })
          .then(() => setSkill(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const eraseForm = () => {
    setSkill("");
  };

  const listSkills = () => {
    return skills
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((skill) => {
        return (
          <>
            <tr>
              <td>{skill.number}</td>
              <td>{skill.name}</td>
              <td>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSkill(skill);
                    setShowDelete(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                    color="blue"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </span>
                &nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSkill(skill);
                    setShowDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                    color="red"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span>
              </td>
            </tr>
          </>
        );
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={9}>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Compétition</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{skills && listSkills()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && (
            <Col>
              <h3>{!skill.number ? "Ajouter un " : "Modifier le"} métier</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                {!skill.number && (
                  <>
                    <Row>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="ID du métier"
                          name="number"
                          value={skillAdd.number}
                          onChange={(e) =>
                            setSkillAdd({
                              ...skillAdd,
                              number: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="Nom du métier"
                          name="name"
                          value={skillAdd.name}
                          onChange={(e) =>
                            setSkillAdd({
                              ...skillAdd,
                              name: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                  </>
                )}
                {skill.number && (
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du métier"
                        name="name"
                        value={skill && skill.name}
                        onChange={(e) =>
                          setSkill({
                            ...skill,
                            name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                )}
                <Row>
                  <Col>
                    {!skill.number && (
                      <Button
                        onClick={() => addSkill()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {skill.number && (
                      <>
                        <Button
                          onClick={() => updateSkill()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col>
              <h3>Supprimer {skill.name}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression de ce métier et des éléments associés.
                    <br />
                    Pour confirmer la suppression veuillez saisir le nom de la
                    compétition et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du métier à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteSkill()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setSkill("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Skills;
