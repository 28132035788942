import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import * as _api from "../../Apis/Comments_apis";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import { BsHeartFill, BsHeart, BsChatDotsFill } from "react-icons/bs";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from "react";
import { useContext } from "react";
import { userContext } from "../../Stores/Store";

const CommentaireView = (props) => {
  const [commentaire, setCommentaire] = useState(props.data);
  const { keycloak, initialized } = useKeycloak();
  const [editionMode, setEditionMode] = useState(false);
  const user = useContext(userContext);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(commentaire.content))
      )
    );
  }, []);

  const handleLike = () => {
    _api
      .postLikeComment(keycloak.token, props.module, commentaire.id)
      .then((results) => {
        if (results) {
          if (commentaire.parent_content)
            results.parent_content = commentaire.parent_content;
          setCommentaire(results);
        } else {
          // setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  const handleUnlike = () => {
    _api
      .postUnLikeComment(keycloak.token, props.module, commentaire.id)
      .then((results) => {
        if (results) {
          if (commentaire.parent_content)
            results.parent_content = commentaire.parent_content;
          setCommentaire(results);
        } else {
          // setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  const updateComment = () => {
    if (editorState.getCurrentContent()) {
      commentaire.content = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      commentaire.notions = [];
      _api
        .postComment(keycloak.token, props.module, commentaire.id, commentaire)
        .then((results) => {
          if (results) {
            if (commentaire.parent_content)
              results.parent_content = commentaire.parent_content;
            setCommentaire(results);
            setEditionMode(false);
          } else {
            // setErrorForm("Une erreur technique s'est produite");
          }
        });
    }
  };

  const replaceUsername = (username) => {
    let fullname = user.contactsSkill.find((author) => {
      return author.username === username
    })
    return fullname ? fullname.first_name+" "+fullname.last_name : username
  }

  return (
    <>
      <Card key={commentaire.id}>
        <Card.Header>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-person-fill"
            viewBox="0 0 16 16"
            color="grey"
          >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          &nbsp;{replaceUsername(commentaire.author)},&nbsp;
          {commentaire.modified_date
            ? "modifié le " +
              moment(commentaire.modified_date).format("D/MM/yyyy")
            : "crée le " + moment(commentaire.date).format("D/MM/yyyy")}
          <span style={{ float: "right" }}>
            <a
              style={{ cursor: "pointer" }}
              title="Répondre au commentaire"
              onClick={() =>
                props.reply({
                  id: commentaire.id,
                  content: commentaire.content
                    .replace(/<[^>]*>/g, "")
                    .substr(0, 50),
                  author: commentaire.author,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-reply-fill"
                viewBox="0 0 16 16"
              >
                <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
              </svg>
            </a>
            &nbsp;
            &nbsp;
            {keycloak.tokenParsed.preferred_username == commentaire.author && (
              <a
                style={{ cursor: "pointer" }}
                title="Editer le commentaire"
                onClick={() => setEditionMode(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </a>
            )}
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            {!editionMode ? (
              <span
                dangerouslySetInnerHTML={{ __html: commentaire.content }}
              ></span>
            ) : (
              <>
                <Editor
                  wrapperClassName="comment-wrapper"
                  editorClassName="comment-editor"
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    options: ["inline", "list", "link", "history", "emoji"],
                  }}
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: [],
                  }}
                />
                <Button onClick={() => updateComment()}>Modifier</Button>
                <Button
                  onClick={() => setEditionMode(false)}
                  variant={"outline-danger"}
                >
                  Annuler
                </Button>
              </>
            )}
          </Card.Text>
        </Card.Body>

        {commentaire.parent_content && (
          <footer className="reply">
            En réponse à "
            {commentaire.parent_content.replace(/<[^>]+>/g, "").substr(0, 50)}"
          </footer>
        )}
        <Card.Footer className="text-muted">
          {" "}
          {!commentaire.is_liked ? (
            <BsHeart onClick={() => handleLike(commentaire.id)} />
          ) : (
            <BsHeartFill
              color="red"
              onClick={() => handleUnlike(commentaire.id)}
            />
          )}
          <span> {commentaire.nb_likes} </span>
          {commentaire.nb_references > 0 ? (
            <BsChatDotsFill color="blue" />
          ) : (
            <BsChatDotsFill color="grey" />
          )}
          <span> {commentaire.nb_references}</span>
        </Card.Footer>
      </Card>
    </>
  );
};

export default CommentaireView;
