import React from "react";
import { useEffect } from "react";
import { userContext } from "../../Stores/Store";
import { useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";
import * as _api from "../../Apis/Questions_apis";
import { useState } from "react";
import { Button, Card, Col, Image, Row, Stack, Table } from "react-bootstrap";
import moment from "moment/moment";
import { BsCheckCircle, BsClipboard } from "react-icons/bs";
import Loading from "../../Helpers/Loading";

const Questionnaires = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [questions, setQuestions] = useState();

  const openQuestion = (id) => {
    if (id) {
      _api
        .postQuestion(keycloak.token, id)
        .then((results) => {
          if (results) {
            let arrayTemp;
            let index = questions.findIndex((obj) => obj.id == results.id);
            arrayTemp = [...questions];
            arrayTemp[index] = results;
            setQuestions(arrayTemp);
            return results;
          }
        })
        .then((results) => window.open(results.link, "_blank"));
    }
  };

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getQuestions(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setQuestions(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const listQuestions = () => {
    return questions.map((question) => {
      return (
        <Card key={question.id}>
          <Stack direction="horizontal" gap={3}>
            <div className="p-2">
              <b>{question.name}</b>
            </div>
            <div className="p-2 ms-auto">
              {question.last_visit ? (
                <Button variant="light" onClick={() => openQuestion(question.id)}>
                  Accèder au questionnaire
                </Button>
              ) : (
                <Button onClick={() => openQuestion(question.id)}>
                Accéder au questionnaire
              </Button>
              )}
            </div>
          </Stack>

          <Stack direction="horizontal" gap={3}>
            <div className="p-2">
              {question.update_date && (
                <i>
                  Mis à jour le{" "}
                  {moment(question.update_date).format("DD-MM-YYYY")}
                </i>
              )}
              {question.comment && <>Commentaire : {question.comment}</>}
            </div>
            <div className="p-2 ms-auto">
              {question.last_visit ? (
                <>
                  <BsCheckCircle color="green" /> Visité le{" "}
                  {moment(question.last_visit).format("DD-MM-YYYY à HH:mm")}
                </>
              ) : (
                <i>Questionnaire non ouvert</i>
              )}
            </div>
          </Stack>
        </Card>
      );
    }).sort((a,b) => (moment(a.last_visit) - moment(b.last_visit) ? 1 : -1));
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header">
        <Col sm={3}>
          <Image
            className="img_title"
            src="images/icons/bigmenu_questionnaire.svg"
          />
          <span className="title">Questionnaires</span>
        </Col>
      </Row>
      <Row>
        <Col>{questions && listQuestions()}</Col>
      </Row>
    </>
  );
};

export default Questionnaires;
